// get cssgrid working for the extra +15 button et al.
import '../style.css';
import m from 'mithril';
import { preambleMC } from './preamble.js';
import { habitSection } from './habit.js';
import { beginTSSection } from './beginTS.js';
import { endTSSection } from './endTS.js';
import { saveButtonSection } from './saveButton.js';

let v3mMainSplash = {
  view: function(vnode) {
    let result = [
      m(preambleMC, vnode.attrs), // preambleMC has two components sharing the same grid
      m("a", {
        href: `./#!/v3/admin/${vnode.attrs.uuencodedParameters}`,
        class: 'adminURL',
      }, "admin"),
      habitSection.map(j => m(j, vnode.attrs)),
      beginTSSection.map(j => m(j, vnode.attrs)),
      endTSSection.map(j => m(j, vnode.attrs)),
      saveButtonSection.map(j => m(j, vnode.attrs)),
    ];
    
    return(result);
  }
};

export { v3mMainSplash };