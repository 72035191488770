import m from 'mithril';
import { V3sendState2URL, V3sendStateOL2URL } from './common.js';
import { nowYYYYMMDD, nowHHMMSS, addMinutes, toPlainTS, toSplitPlainTS } from '../../util.v3.js';

var endTSLabelMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    return(
      m("label", {
        for: "endTS",
        class: "endDTLabel"
      }, `End Date/Time: ${decodedParms.endTime}`)
    );
  }
};

var endDateMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    var result = m("input", {
      class: "endYYYYMMDD",
      type: "date",
      id: "endDate",
      name: "endDate",
      value: decodedParms.endDate,
      onchange: function(e) {
        decodedParms.endDate = e.target.value;
        V3sendStateOL2URL(decodedParms);
      }
    });
    return(result);
  }
}
var endTimeMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    var result = m("input", {
      class: "endHHMMSS",  
      type: "time",
      id: "endTime",
      name: "endTime",
      value: decodedParms.endTime,
      onchange: function(e) {
        decodedParms.endTime = e.target.value;
        V3sendStateOL2URL(decodedParms);
      }
    });
    return(result);
  }
};

var endTSButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    var result = m("button", {
      class: "endTSPlus0",
      onclick: function(e) {
        decodedParms.endDate = nowYYYYMMDD();
        decodedParms.endTime = nowHHMMSS();
        V3sendStateOL2URL(decodedParms);
      }
    }, "TODAY");
    return(result);
  }
};

var endTSPlus11ButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    
    var result = m("button", {
      class: "endTSPlusSmall",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
          YYYYMMDD: decodedParms.beginDate,
          HHMMSS: decodedParms.beginTime
        });
        const endJSDT = toPlainTS({
            YYYYMMDD: decodedParms.endDate,
            HHMMSS: decodedParms.endTime
          });
        const deltaMM = 11;
        
        let newEndTS = toSplitPlainTS(addMinutes(beginJSDT, deltaMM));
        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          let newEndTS = toSplitPlainTS(addMinutes(endJSDT, deltaMM));
        }

        decodedParms.endDate = newEndTS.YYYYMMDD,
        decodedParms.endTime = newEndTS.HHMMSS,
        V3sendStateOL2URL(decodedParms);
      }
    }, "+11min");
    return(result);
  }
};

var endTSPlus61ButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    var result = m("button", {
      class: "endTSPlusBig",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
          YYYYMMDD: decodedParms.beginDate,
          HHMMSS: decodedParms.beginTime
        });
        const endJSDT = toPlainTS({
            YYYYMMDD: decodedParms.endDate,
            HHMMSS: decodedParms.endTime
          });
        const deltaMM = 61;
        
        console.log(`beginJSDT: ${beginJSDT}`);
        console.log(`endJSDT: ${endJSDT}`);
        console.log(`comparison result: ${beginJSDT > endJSDT}`);
        
        console.log("new end time is old time + 61");
        let newEndTS = toSplitPlainTS(addMinutes(beginJSDT, deltaMM));
        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          let newEndTS = toSplitPlainTS(addMinutes(endJSDT, deltaMM));
          console.log("new end time is new time + 61");
        }

        decodedParms.endDate = newEndTS.YYYYMMDD,
        decodedParms.endTime = newEndTS.HHMMSS,
        V3sendStateOL2URL(decodedParms);
      }
    }, "+61min");
    return(result);
  }
};

var endTSSection = [
  endTSLabelMC,
  endDateMC,
  endTimeMC,
  endTSButtonMC,
  endTSPlus11ButtonMC,
  endTSPlus61ButtonMC,
];
export { endTSSection };