import m from 'mithril';
// import { virtues } from '../../virtues.js';
import { V3sendState2URL, V3sendStateOL2URL } from './common.js';

var habitLabelMC = {
  view: function(vnode) {
    return(m("label", {
      for: "habit",
      class: "habitLabel"
    }, "Habit"));
  } 
};

var habitListMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    let virtues = decodedParms.habitList;
    let virtueList = [m("option", { value: " " }, "Pick a virtue")].concat(virtues.map(i => m("option", {value: i}, i)));
    
    let result = m("select", {
      id: "habit",
      class: "habitList",
      name: "habit",
      value: decodedParms.habit,
      onchange: function(e) {
        decodedParms.habit = e.target.value;
        V3sendStateOL2URL(decodedParms);
      }
    }, virtueList);
    // vnode.attrs.uuencodedParameters);
    return(result);
  }
};

var habitTextMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    
    let result = m("input", {
        type: "text",
        class: "habitText",
        id: "habitFreeText",
        name: "habitFreeText",
        value: decodedParms.habit,
        onchange: function(e) {          
          decodedParms.habit = e.target.value;
          V3sendStateOL2URL(decodedParms);
        }
      });
    return(result);
  }
};

var habitSection = [
  habitLabelMC,
  habitListMC,
  habitTextMC
];

export { habitSection };