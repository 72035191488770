import m from 'mithril';
import { V3sendState2URL, V3sendStateOL2URL } from './common.js';
import { tsDiff } from '../../util.v3.js';
import { V2reqWRetry } from '../../gapiHelpers.js';

let saveButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    let result = m("button", {
        class: "saveHabitButton",
        onclick: function() {
          decodedParms.habit += tsDiff({
              begin_YYYYMMDD: decodedParms.beginDate,
              begin_t: decodedParms.beginTime,
              end_YYYYMMDD: decodedParms.endDate,
              end_t: decodedParms.endTime
            }); 
          console.log(`saveButton debug: ${JSON.stringify(decodedParms)}`);
          V2reqWRetry(decodedParms);
        }
      }, "Log Habit");
    return(result);
  }
};

let saveButtonSection = [
  saveButtonMC,
];

export { saveButtonSection };