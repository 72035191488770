import m from 'mithril';

function V3sendState2URL(stateParm) {
  // console.log("v2ssurl: reached here");  
  // console.log(`v2ssurl: ${JSON.stringify(stateParm)}`);
  m.route.set(`/v3/${stateParm}`);
}

function V3sendStateOL2URL(parameterObjectLiteral) {
  // send state object literal to URL
  let encodedParms = encodeURIComponent(JSON.stringify(parameterObjectLiteral));
  m.route.set(`/v3/${encodedParms}`);
}

function V3sendStateOL2adminURL(parameterObjectLiteral) {
  // send state object literal to admin URL
  let encodedParms = encodeURIComponent(JSON.stringify(parameterObjectLiteral));
  m.route.set(`/v3/admin/${encodedParms}`);
}

export { V3sendState2URL, V3sendStateOL2URL, V3sendStateOL2adminURL };