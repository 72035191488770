import m from 'mithril';
import { UUTestHarnessMC } from './UUTestHarness.js';

let titleMC = {
  view: function(vnode) {
    return(m("b", {}, "Groovate V3 - uuencode all parameters"));
  }
};

let legendMC = {
  view: function(vnode) {
    return(m("div", {}, "Start a habit and monitor habituation progress through a calendar"));
  }
};

let buildRefMC = {
  view: function(vnode) {
    return(m("div", {}, `Build: ${BUILD_REF}`));
  }
};

let buildTSMC = {
  view: function(vnode) {
    return(m("div", {}, `Built: ${BUILD_TIMESTAMP}`));
  }
};

let preambleMC = {
  view: function(vnode) {
    let result = m("div",
                   {class: "header"},
                   [
                    titleMC,
                    buildRefMC,
                    buildTSMC,
                    // UUTestHarnessMC, // POC for habits list in URL
                  ].map(x => m(x, vnode.attrs)));
    return(result);
  }
}

export { preambleMC };