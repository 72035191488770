// if you wrap m in curly braces, the code fails silently
// import m from 'mithril/mithril.min.js'; // this also works
// from https://mithril.js.org/installation.html
import m from 'mithril';
// require doesn't work either
// var m = require("mithril");
import { customDate, customTime } from './util.js';

// gui.cssgrid is broken at the moment, rebuild a smaller MVP for mithril and css grid
import { v2mSplash } from './gui.cssgrid/v2Splash.js';
import { v3mMainSplash } from './gui.cssgrid/v3/mainSplash.js';
import { v3mAdminSplash } from './gui.cssgrid/v3/adminSplash.js';
// import { v2mSplash } from './gui.just-flexbox/v2Splash.js';

function mountMithril() {
  var root = document.getElementById('mithrilSpace');
  m.route(root, `/v2/${customDate()}/${customTime()}/${customDate()}/${customTime()}/ /`, {
    "/v2/:beginDate/:beginTime/:endDate/:endTime/:habit/": v2mSplash,
    "/v3/": v3mAdminSplash,
    "/v3/admin/": v3mAdminSplash,
    "/v3/admin/:uuencodedParameters/": v3mAdminSplash,
    "/v3/:uuencodedParameters/": v3mMainSplash,
  });
}

export { mountMithril };
