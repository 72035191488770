// get cssgrid working for the extra +15 button et al.
import './style.admin.css';
import m from 'mithril';
import { preambleMC } from './preamble.js';
import { habitSection } from './habit.js';
import { beginTSSection } from '../beginTS.js';
import { endTSSection } from '../endTS.js';
import { saveButtonSection } from '../saveButton.js';
import { nowYYYYMMDD, nowHHMMSS, addMinutes } from '../../util.v3.js';
import { V3sendStateOL2adminURL } from './common.js';

let habits = ["cardio", "weights", "wakeup early", "sleep early"];
// default config in case URL is empty
let config = {
    habitList: habits,
    habit: '',
    beginDate: nowYYYYMMDD(),
    endDate: nowYYYYMMDD(),
    beginTime: nowHHMMSS(),
    endTime: nowHHMMSS(),
};

let virtueLI = {
    view: function(vnode) {
        let virtue = vnode.attrs.name;
        return(m("li", {
            name: virtue,
            onclick: function (e) {
                const index = config.habitList.indexOf(virtue);
                console.log(`delete debug: index = ${index}`);
                console.log(`delete debug#2: ${JSON.stringify(virtue)}`);
                if (index > -1) { // only splice array when item is found
                    config.habitList.splice(index, 1); // 2nd parameter means remove one item only
                    V3sendStateOL2adminURL(config);
                }
            }
        }, virtue));
    }
};

let v3mAdminSplash = {
  view: function(vnode) {
    // if uuencodedParameters exist, overwrite config with uuencodedParameters
    if (vnode.attrs.hasOwnProperty("uuencodedParameters")) {
        console.log(`setting config from vnode.attrs.uuencodedParameters`);
        config = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));        
    }

    let habitLabel = m("label", {
        for: "habit",
        class: "adminHabitLabel"
      }, "Habit");

    let habit = m("input", {
        class: "adminHabitText",
        type: "text",
        value: "",
        onchange: function (e) {
            console.log(`before: ${config.habitList.length}`);
            config.habitList.push(e.target.value);
            V3sendStateOL2adminURL(config);
            console.log(`after: ${config.habitList.length}`);
        }
    });

    let virtues =m("ul", { class: "adminVirtueList" }, config.habitList.map(i => m(virtueLI, {name: i})));

    let configD = m("div", { class: "adminConfigD"}, `JSON is ${Object.keys(config).length}`);

    let encodedConfigD = m("div", { class: "adminEncodedConfigD"}, `set config debug: ${config.habitList}`);

    let URLwithConfig = m("a", {
        class: "adminURLwithConfig",
        // href: `https://gv.kkwoo.au/#!/v3/%5B%22cardio%22%2C%22weights%22%2C%22wakeup%20early%22%2C%22sleep%20early%22%5D/`,
        href: `./#!/v3/${encodeURIComponent(JSON.stringify(config))}/`,
    }, "Load with config");

    let urlStateD = m("div", { class: "urlStateD"}, `URL state is ${JSON.stringify(config)}`);

    let result = [
      m(preambleMC, vnode.attrs), // preambleMC has two components sharing the same grid
      habitLabel,
      habit,
      URLwithConfig,
      virtues,
      /* configD,
      encodedConfigD,
      urlStateD, */
    ];
    
    return(result);
  }
};

export { v3mAdminSplash };