import m from 'mithril';
import { V3sendState2URL, V3sendStateOL2URL } from './common.js';
import { nowYYYYMMDD, nowHHMMSS, addMinutes, toPlainTS, toSplitPlainTS } from '../../util.v3.js';

let beginTSLabelMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    return(
      m("label", {
        for: "beginTS",
        class: "beginDTLabel"
      }, `Begin Date/Time: ${decodedParms.beginTime}`)
    );
  }
};

let beginDateMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    let result = m("input", {
      class: "beginYYYYMMDD",
      type: "date",
      id: "beginDate",
      name: "beginDate",
      value: decodedParms.beginDate,
      onchange: function(e) {
        decodedParms.beginDate = e.target.value;
        V3sendStateOL2URL(decodedParms);
      }
    });
    return(result);
  }
}
let beginTimeMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));
    
    let now = new Date();
    let defaultBT = now.toTimeString().slice(0, 8);
    if (decodedParms.hasOwnProperty("beginTime")) {
      let defaultBT = decodedParms.beginTime;
    }
    
    let result = m("input", {
      class: "beginHHMMSS",  
      type: "time",
      id: "beginTime",
      name: "beginTime",
      value: decodedParms.beginTime,
      onchange: function(e) {
        decodedParms.beginTime = e.target.value;
        V3sendStateOL2URL(decodedParms);
      }
    });
    return(result);
  }
};

let beginTSButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    let result = m("button", {
      class: "beginTSButton",
      onclick: function(e) {
        decodedParms.beginDate = nowYYYYMMDD();
        decodedParms.beginTime = nowHHMMSS();
        V3sendStateOL2URL(decodedParms);
      }
    }, "TODAY");
    return(result);
  }
};

let beginTSMinus5ButtonMC = {
  view: function(vnode) {
    let decodedParms = JSON.parse(decodeURIComponent(vnode.attrs.uuencodedParameters));

    let result = m("button", {
      class: "beginTSMinusSmall",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
            YYYYMMDD: decodedParms.beginDate,
            HHMMSS: decodedParms.beginTime
          });
        const endJSDT = toPlainTS({
            YYYYMMDD: decodedParms.endDate,
            HHMMSS: decodedParms.endTime
          });
        const deltaMM = -5;
        
        let newEndTS = toSplitPlainTS(addMinutes(beginJSDT, deltaMM));
        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          let newEndTS = toSplitPlainTS(addMinutes(endJSDT, deltaMM));
        }

        decodedParms.beginDate = newEndTS.YYYYMMDD,
        decodedParms.beginTime = newEndTS.HHMMSS,
        V3sendStateOL2URL(decodedParms);
      }
    }, "-5min");
    return(result);
  }
};

let beginTSSection = [
  beginTSLabelMC,
  beginTimeMC,
  beginDateMC,
  beginTSButtonMC,
  beginTSMinus5ButtonMC,
];

export { beginTSSection };